.cards {
  margin-top: 3px;
}
.cards p {
  margin-bottom: 0rem !important;
}
.printButton {
  right: 1px;
  float: right;
}
